import React, { useEffect, useState } from "react";

const RadioHealthQn = (props) => {
  const [Diabetes, SetDiabetes] = useState("");
  const [Hemoglobin,SetHemoglobin]=useState('')
  const [Glucose,SetGlucose]=useState('')
  const [BloodPressure,SetBloodPressure]=useState('')
  const [HeartRate,SetHeartRate]=useState('')

  const handleOptionChange = (e) => {
    SetDiabetes(e.target.value);
  };

  const handleOptionChangeHemoglobin = (e) => {
    SetHemoglobin(e.target.value);
  };

  const handleOptionChangeGlucose = (e) => {
    SetGlucose(e.target.value);
  };
  const handleOptionChangeBloodPressure = (e) => {
    SetBloodPressure(e.target.value);
  };
  const handleOptionChangeHeartRate = (e) => {
    SetHeartRate(e.target.value);
  };

  // useEffect(() => {
  //   setTimeout(() => {}, 1);
  //   if (props.SetShowSubmitButton) {
  //     props.SetShowSubmitButton(false);
  //   }
  // }, []);

  useEffect(() => {
   
    if (props.DiabetesPC) {
      SetDiabetes(props.DiabetesPC);
    }
    if (props.HemoglobinPC) {
      SetHemoglobin(props.HemoglobinPC);
    }
    if (props.GlucosePC) {
      SetGlucose(props.GlucosePC);
    }
    if (props.BloodPressurePC) {
      SetBloodPressure(props.BloodPressurePC);
    }
    if (props.HeartRatePC) {
      SetHeartRate(props.HeartRatePC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
   
      props.SetDiabetesPC(Diabetes);
      props.SetHemoglobinPC(Hemoglobin);
      props.SetGlucosePC(Glucose);
      props.SetBloodPressurePC(BloodPressure);
      props.SetHeartRatePC(HeartRate);

      var Err = 0;
      if (Diabetes === "" || Hemoglobin==='' || Glucose==='' || BloodPressure==='' || HeartRate==='') {
        Err = 1;
      }
     

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [Diabetes,Hemoglobin,Glucose,BloodPressure,HeartRate]);

  return (
    <div className="daily_activity radio_health_qn">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
            Have you been diagnosed with prediabetes or type 2 diabetes?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes - I have been previously diagnosed"
            checked={Diabetes === "Yes - I have been previously diagnosed"}
            onChange={handleOptionChange}
          />
          <label>Yes - I have been previously diagnosed</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="No - I have not been diagnosed"
            checked={Diabetes === "No - I have not been diagnosed"}
            onChange={handleOptionChange}
          />
          <label>No - I have not been diagnosed</label>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>What was your most recent hemoglobin A1c (HbA1c) value?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Less than 5.7%"
            checked={Hemoglobin === "Less than 5.7%"}
            onChange={handleOptionChangeHemoglobin}
          />
          <label>Less than 5.7%</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Between 5.7% and 6.4%"
            checked={Hemoglobin === "Between 5.7% and 6.4%"}
            onChange={handleOptionChangeHemoglobin}
          />
          <label>Between 5.7% and 6.4%</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Greater than or equal to 6.4%"
            checked={Hemoglobin === "Greater than or equal to 6.4%"}
            onChange={handleOptionChangeHemoglobin}
          />
          <label>Greater than or equal to 6.4%</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Unsure"
            checked={Hemoglobin === "Unsure"}
            onChange={handleOptionChangeHemoglobin}
          />
          <label>Unsure</label>
        </div>
      </div>


      <div className="input_row">
        <div>
          <label>What was your most recent fasting glucose value?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Fasting Glucose < 100"
            checked={Glucose === "Fasting Glucose < 100"}
            onChange={handleOptionChangeGlucose}
          />
          <label>Fasting Glucose {'<'} 100</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Fasting Glucose >= 100 & <120"
            checked={Glucose === "Fasting Glucose >= 100 & <120"}
            onChange={handleOptionChangeGlucose}
          />
          <label>{'Fasting Glucose >= 100 & <120'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Fasting Glucose >= 120"
            checked={Glucose === "Fasting Glucose >= 120"}
            onChange={handleOptionChangeGlucose}
          />
          <label>{'Fasting Glucose >= 120'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Unsure"
            checked={Glucose === "Unsure"}
            onChange={handleOptionChangeGlucose}
          />
          <label>Unsure</label>
        </div>
      </div>



      <div className="input_row">
        <div>
          <label>What is your current or average blood pressure range?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="< 100/60"
            checked={BloodPressure === "< 100/60"}
            onChange={handleOptionChangeBloodPressure}
          />
          <label>{'< 100/60'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="100/60 - 120/80"
            checked={BloodPressure === "100/60 - 120/80"}
            onChange={handleOptionChangeBloodPressure}
          />
          <label>{'100/60 - 120/80'}</label>
        </div>


        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="121/81 - 140/90"
            checked={BloodPressure === "121/81 - 140/90"}
            onChange={handleOptionChangeBloodPressure}
          />
          <label>{'121/81 - 140/90'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="141/91 - 160/100"
            checked={BloodPressure === "141/91 - 160/100"}
            onChange={handleOptionChangeBloodPressure}
          />
          <label>{'141/91 - 160/100'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="> 160/100"
            checked={BloodPressure === "> 160/100"}
            onChange={handleOptionChangeBloodPressure}
          />
          <label>{'> 160/100'}</label>
        </div>


      
       
      </div>


      <div className="input_row">
        <div>
          <label>What is your current or average heart rate range?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="< 60 beats per minute"
            checked={HeartRate === "< 60 beats per minute"}
            onChange={handleOptionChangeHeartRate}
          />
          <label>{'< 60 beats per minute'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="60-100 beats per minute"
            checked={HeartRate === "60-100 beats per minute"}
            onChange={handleOptionChangeHeartRate}
          />
          <label>{'60-100 beats per minute'}</label>
        </div>


        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="101-110 beats per minute"
            checked={HeartRate === "101-110 beats per minute"}
            onChange={handleOptionChangeHeartRate}
          />
          <label>{'101-110 beats per minute'}</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="> 110 beats per minute"
            checked={HeartRate === "> 110 beats per minute"}
            onChange={handleOptionChangeHeartRate}
          />
          <label>{'> 110 beats per minute'}</label>
        </div>

         </div>



    </div>
  );
};
export default RadioHealthQn;
