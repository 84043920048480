import React, { useEffect, useState } from "react";

const MedicalAllergies = (props) => {
  const [MedicationAllergies, SetMedicationAllergies] = useState("");
  const [MedicationAllergiesDesc, SetMedicationAllergiesDesc] = useState("");
  const handleOptionChange = (e) => {
    SetMedicationAllergies(e.target.value);
  };

  // useEffect(() => {
  //   setTimeout(() => {}, 1);
  //   if (props.SetShowSubmitButton) {
  //     props.SetShowSubmitButton(false);
  //   }
  // }, []);

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.MedicationAllergiesPC) {
      SetMedicationAllergies(props.MedicationAllergiesPC);
    }
    if (props.MedicationAllergiesDescPC) {
      SetMedicationAllergiesDesc(props.MedicationAllergiesDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetMedicationAllergiesDescPC(MedicationAllergiesDesc);
      props.SetMedicationAllergiesPC(MedicationAllergies);

      var Err = 0;
      if (MedicationAllergies === "") {
        Err = 1;
      } else if (MedicationAllergies === "Yes" && MedicationAllergiesDesc === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [MedicationAllergies, MedicationAllergiesDesc]);

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          Do you have any medication allergies?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={MedicationAllergies === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={MedicationAllergies === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

      {MedicationAllergies == "Yes" ? (
        <div className="input_row">
          <div>
            <textarea
                 rows={6}
              className="textarea_row"
              defaultValue={MedicationAllergiesDesc}
              value={MedicationAllergiesDesc}
              onChange={(e) => {
                SetMedicationAllergiesDesc(e.target.value);
              }}
              placeholder="If yes, list your medication allergies*"
            ></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default MedicalAllergies;
