import React, { useEffect, useState, useRef } from "react";
import mainLogo from '../../../images/fitpal_logo.png'; 
import QuizForm from "./QuizForm";

const AddQuiz=()=>{

    return (
        <div className="main_container_quiz">
            <div className="top_logo">
 <img src={mainLogo}/>
            </div>
            <div>
                <QuizForm/>
            </div>
        </div>
    )

}
export default AddQuiz