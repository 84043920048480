import React, { useEffect, useState } from "react";
import MultiStep from "react-multistep";
import BasicInfoForm from "./BasicInfoForm";
import SelfMedication from "./SelfMedication";
import MedicalAllergies from "./MedicalAllergies";
import AnythingApply from "./Anythingapply";
import SelfMedicationBefore from "./SelfMedicationBefore";
import OpiatePainMedication from "./OpiatePainMedication";
import Bariatric from "./Bariatric";
import WeightManagement from "./WeightManagment";
import WillingTo from "./WillingTo";
import WeightChanged from "./WeightChanged";
import TheseApply from "./TheseApply";
import RadioHealthQn from "./RadioHealthQn";
import FinalInfo from "./FinalInfo";
import BelugaMedicineDetails from "./BelugaMedicineDetails";
import axios from "axios";

const BelugaVisitForm=()=>{

  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");

    const [FirstNamePC,SetFirstNamePC]=useState('')
    const [LastNamePC,SetLastNamePC]=useState('')
    const [PhonePC,SetPhonePC]=useState('')
    const [EmailAddressPC,SetEmailAddressPC]=useState('')
    const [AddressPC,SetAddressPC]=useState('')
    const [StatePC,SetStatePC]=useState('')
    const [CityPC,SetCityPC]=useState('')
    const [ZipPC,SetZipPC]=useState('')
    const [SexPC,SetSexPC]=useState('')
    const [FirstCallBasicInfo,SetFirstCallBasicInfo]=useState(false)


    const [SelfMedicationPC, SetSelfMedicationPC] = useState("");
    const [SelfMedicationDescPC, SetSelfMedicationDescPC] = useState("");

    const [MedicationAllergiesPC, SetMedicationAllergiesPC] = useState("");
    const [MedicationAllergiesDescPC, SetMedicationAllergiesDescPC] = useState("");

    const [AnythingApplyPC,SetAnythingApplyPC]=useState([])
    const [AnythingApplyLengthPC,SetAnythingApplyLengthPC]=useState(0)

    const [AnythingApplyPCT,SetAnythingApplyPCT]=useState([])
    const [AnythingApplyLengthPCT,SetAnythingApplyLengthPCT]=useState(0)

    const [WillingToPC,SetWillingToPC]=useState([])
    const [WillingToLengthPC,SetWillingToLengthPC]=useState(0)



    const [SelfMedicationBeforePC, SetSelfMedicationBeforePC] = useState("");
    const [SelfMedicationDescBeforePC, SetSelfMedicationDescBeforePC] = useState("");

    const [OpMedicationPC, SetOpMedicationPC] = useState("");
    const [OpMedicationDescPC, SetOpMedicationDescPC] = useState("");

    const [BariatricPC, SetBariatricPC] = useState("");
    const [BariatricDescPC, SetBariatricDescPC] = useState("");

    const [WeightManagementPC, SetWeightManagementPC] = useState("");
    const [WeightManagementDescPC, SetWeightManagementDescPC] = useState("");

    const [WeightChangedPC, SetWeightChangedPC] = useState("");


    const [DiabetesPC, SetDiabetesPC] = useState("");
    const [HemoglobinPC,SetHemoglobinPC]=useState('')
    const [GlucosePC,SetGlucosePC]=useState('')
    const [BloodPressurePC,SetBloodPressurePC]=useState('')
    const [HeartRatePC,SetHeartRatePC]=useState('')

    const [DoctorInfoPC, SetDoctorInfoPC] = useState("");
    const [HeightPC, SetHeightPC] = useState("");
    const [WeightPC, SetWeightPC] = useState("");
    const [InsurancePC, SetInsurancePC] = useState("");
    const [AdditionalInsurancePC, SetAdditionalInsurancePC] = useState("");

    const [VisitTypePC,SetVisitTypePC]=useState('')
    const [PreganantPC,SetPreganantPC]=useState('')
    const [BreastFeedingPC,SetBreastFeedingPC]=useState('')
    const [PregnantConsentPC,SetPregnantConsentPC]=useState(false);

    const DisableNextButton = () => {
        const NextButton = document.querySelectorAll(
          ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:last-child"
        );
        NextButton[0].disabled = true;
      };
    
      const EnableNextButton = () => {
        const NextButton = document.querySelectorAll(
          ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:last-child"
        );
        NextButton[0].disabled = false;
      };
      const [ShowSubmitButton, SetShowSubmitButton] = useState(false);

    const steps = [
        {
         
          title: "StepOne",
          component: (
            <BasicInfoForm
            FirstNamePC={FirstNamePC}
            SetFirstNamePC={SetFirstNamePC}
            LastNamePC={LastNamePC}
            SetLastNamePC={SetLastNamePC}
            PhonePC={PhonePC}
            SetPhonePC={SetPhonePC}
            EmailAddressPC={EmailAddressPC}
            SetEmailAddressPC={SetEmailAddressPC}
            AddressPC={AddressPC}
            SetAddressPC={SetAddressPC}
            StatePC={StatePC}
            SetStatePC={SetStatePC}
            CityPC={CityPC}
            SetCityPC={SetCityPC}
            ZipPC={ZipPC}
            SetZipPC={SetZipPC}
            SexPC={SexPC}
            SetSexPC={SetSexPC}
            FirstCallBasicInfo={FirstCallBasicInfo}
            SetFirstCallBasicInfo={SetFirstCallBasicInfo}
              DisableNextButton={DisableNextButton}
              EnableNextButton={EnableNextButton}
              SetShowSubmitButton={SetShowSubmitButton}
              SetPreviousMenu={EnableNextButton}
              SetNextMenu ={DisableNextButton }
            />
            
          ),
        },
        // {
        //   title: "StepTwo",
        //   component: (
        //     <BelugaMedicineDetails
        //     VisitTypePC={VisitTypePC}
        //     SetVisitTypePC={SetVisitTypePC}
        //       DisableNextButton={DisableNextButton}
        //       EnableNextButton={EnableNextButton}
        //       SetShowSubmitButton={SetShowSubmitButton}
             
        //     />
            
        //   ),
        // },
        {
            title: "StepTwo",
            component: (
              <SelfMedication
                SexPC={SexPC}
                PreganantPC={PreganantPC}
                SetPreganantPC={SetPreganantPC}
                PregnantConsentPC={PregnantConsentPC}
                SetPregnantConsentPC={SetPregnantConsentPC}
                BreastFeedingPC={BreastFeedingPC}
                SetBreastFeedingPC={SetBreastFeedingPC}
 
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
                SelfMedicationPC={SelfMedicationPC}
                SetSelfMedicationPC={SetSelfMedicationPC}
                SelfMedicationDescPC={SelfMedicationDescPC}
                SetSelfMedicationDescPC={SetSelfMedicationDescPC}
              />
              
            ),
          },
          {
            title: "StepThreee",
            component: (
              <MedicalAllergies
                MedicationAllergiesPC={MedicationAllergiesPC}
                SetMedicationAllergiesPC={SetMedicationAllergiesPC}
                MedicationAllergiesDescPC={MedicationAllergiesDescPC}
                SetMedicationAllergiesDescPC={SetMedicationAllergiesDescPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
            title: "StepFour",
            component: (
              <AnythingApply
                AnythingApplyPC={AnythingApplyPC}
                SetAnythingApplyPC={SetAnythingApplyPC}
                AnythingApplyLengthPC={AnythingApplyLengthPC}
                SetAnythingApplyLengthPC={SetAnythingApplyLengthPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
           
            title: "StepFive",
            component: (
              <SelfMedicationBefore
              SelfMedicationBeforePC={SelfMedicationBeforePC}
              SetSelfMedicationBeforePC={SetSelfMedicationBeforePC}
              SelfMedicationDescBeforePC={SelfMedicationDescBeforePC}
              SetSelfMedicationDescBeforePC={SetSelfMedicationDescBeforePC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
          
            title: "StepSix",
            component: (
              <OpiatePainMedication
              OpMedicationPC={OpMedicationPC}
              SetOpMedicationPC={SetOpMedicationPC}
              OpMedicationDescPC={OpMedicationDescPC}
              SetOpMedicationDescPC={SetOpMedicationDescPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
         
            title: "StepSeven",
            component: (
              <Bariatric
              BariatricPC={BariatricPC}
              SetBariatricPC={SetBariatricPC}
              BariatricDescPC={BariatricDescPC}
              SetBariatricDescPC={SetBariatricDescPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
          
            title: "StepEight",
            component: (
              <WeightManagement
              WeightManagementPC={WeightManagementPC}
              SetWeightManagementPC={SetWeightManagementPC}
              WeightManagementDescPC={WeightManagementDescPC}
              SetWeightManagementDescPC={SetWeightManagementDescPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
           
            title: "StepNine",
            component: (
              <WillingTo
              WillingToPC={WillingToPC}
              SetWillingToPC={SetWillingToPC}
              WillingToLengthPC={WillingToLengthPC}
              SetWillingToLengthPC={SetWillingToLengthPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
         
            title: "StepTen",
            component: (
              <WeightChanged
                WeightChangedPC={WeightChangedPC}
                SetWeightChangedPC={SetWeightChangedPC}
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
            title: "StepEleven",
            component: (
              <TheseApply
              AnythingApplyPCT={AnythingApplyPCT}
              SetAnythingApplyPCT={SetAnythingApplyPCT}
              AnythingApplyLengthPCT={AnythingApplyLengthPCT}
              SetAnythingApplyLengthPCT={SetAnythingApplyLengthPCT}
              
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
           
            title: "StepTwelve",
            component: (
              <RadioHealthQn
                DiabetesPC={DiabetesPC}
                SetDiabetesPC={SetDiabetesPC}
                HemoglobinPC={HemoglobinPC}
                SetHemoglobinPC={SetHemoglobinPC}
                GlucosePC={GlucosePC}
                SetGlucosePC={SetGlucosePC}
                BloodPressurePC={BloodPressurePC}
                SetBloodPressurePC={SetBloodPressurePC}
                HeartRatePC={HeartRatePC}
                SetHeartRatePC={SetHeartRatePC}
            
                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
          {
           
            title: "StepThirteen",
            component: (
              <FinalInfo
              DoctorInfoPC={DoctorInfoPC}
              SetDoctorInfoPC={SetDoctorInfoPC}
              HeightPC={HeightPC}
              SetHeightPC={SetHeightPC}
              WeightPC={WeightPC}
              SetWeightPC={SetWeightPC}
              InsurancePC={InsurancePC}
              SetInsurancePC={SetInsurancePC}
              AdditionalInsurancePC={AdditionalInsurancePC}
              SetAdditionalInsurancePC={SetAdditionalInsurancePC}


                DisableNextButton={DisableNextButton}
                EnableNextButton={EnableNextButton}
                SetShowSubmitButton={SetShowSubmitButton}
              />
              
            ),
          },
    ]
    
    const SubmitVisitForm=async()=>{


      const response = await axiosJWT.post('/beluga/create-visit-backend',
      {
          'firstname': FirstNamePC,
          'lastname': LastNamePC,
          'phone':PhonePC,
          'email':EmailAddressPC,
          'address':AddressPC,
          'city':CityPC,
          'state':StatePC,
          'zip':ZipPC,
          'sex':SexPC,
          'selfReportedMeds':(SelfMedicationPC==='Yes' ? SelfMedicationPC+', '+SelfMedicationDescPC : SelfMedicationPC),
          'allergies':(MedicationAllergiesPC==='Yes' ? MedicationAllergiesPC+', '+MedicationAllergiesDescPC : MedicationAllergiesPC),
          'medicalConditions':(AnythingApplyPC.length>0 ? AnythingApplyPC.toString() : '')
          


      },
      {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
     
    }

       return (
        <>
         <div className="quiz_form_container beluga_quiz_form_container">
           <MultiStep showNavigation={true} steps={steps} />
         </div>
         {ShowSubmitButton === true && (
        <div className="final_submit_button">
          <button onClick={SubmitVisitForm}>Submit</button>
        </div>
      )}
        </>
       )
}
export default BelugaVisitForm