import React, { useEffect, useState } from "react";

const FinalInfo = (props) => {
  const [DoctorInfo, SetDoctorInfo] = useState("");
  const [Height, SetHeight] = useState("");
  const [Weight, SetWeight] = useState("");
  const [Insurance, SetInsurance] = useState("");
  const [AdditionalInsurance, SetAdditionalInsurance] = useState("");
  

  const handleOptionChangeInsurance = (e) => {
    SetInsurance(e.target.value);
  };

  const handleOptionChangeAdditionalInsurance = (e) => {
    SetAdditionalInsurance(e.target.value);
  };

  // useEffect(() => {
  //   setTimeout(() => {}, 1);
  //   if (props.SetShowSubmitButton) {
  //     props.SetShowSubmitButton(false);
  //   }
  // }, []);

  useEffect(() => {
   
    if (props.DoctorInfoPC) {
      SetDoctorInfo(props.DoctorInfoPC);
    }
    if (props.HeightPC) {
      SetHeight(props.HeightPC);
    }
    if (props.WeightPC) {
      SetWeight(props.WeightPC);
    }
    if (props.InsurancePC) {
      SetInsurance(props.InsurancePC);
    }
    if (props.AdditionalInsurancePC) {
      SetAdditionalInsurance(props.AdditionalInsurancePC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
   
      props.SetDoctorInfoPC(DoctorInfo);
      props.SetHeightPC(Height);
      props.SetWeightPC(Weight);
      props.SetInsurancePC(Insurance);
      props.SetAdditionalInsurancePC(AdditionalInsurance);

      var Err = 0;
      if (DoctorInfo === "" || Height==='' || Weight==='' || Insurance==='' || AdditionalInsurance==='') {
        Err = 1;
      }
     

      if (Err == 0) {
      
        props.SetShowSubmitButton(true);
      } else {
        props.DisableNextButton();
        props.SetShowSubmitButton(false);
      }
    }, 1);
  }, [DoctorInfo,Height,Weight,Insurance,AdditionalInsurance]);

  return (
    <div className="daily_activity radio_health_qn">
      <div className="head_row"></div>

    

      <div className="input_row">
        <label>
          Do you have any further information which you would like the doctor to
          know?
        </label>
        <div className="textarea_row">
          <textarea
            rows={6}
            defaultValue={DoctorInfo}
            value={DoctorInfo}
            onChange={(e) => {
              SetDoctorInfo(e.target.value);
            }}
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>What is your height?</label>
        </div>
        <div>
          <input
            type="text"
            defaultValue={Height}
            value={Height}
            onChange={(e) => {
              SetHeight(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>What is your weight?</label>
        </div>
        <div>
          <input
            type="text"
            defaultValue={Weight}
            value={Weight}
            onChange={(e) => {
              SetWeight(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>What’s your insurance?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Employer / Commercial (Aetna or Blue Cross Blue Shield or Federal Employee Program etc...)"
            checked={
              Insurance ===
              "Employer / Commercial (Aetna or Blue Cross Blue Shield or Federal Employee Program etc...)"
            }
            onChange={handleOptionChangeInsurance}
          />
          <label>
            Employer / Commercial (Aetna or Blue Cross Blue Shield or Federal
            Employee Program etc...)
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Kaiser"
            checked={Insurance === "Kaiser"}
            onChange={handleOptionChangeInsurance}
          />
          <label>Kaiser</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Government"
            checked={Insurance === "Government"}
            onChange={handleOptionChangeInsurance}
          />
          <label>Government</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Do not have insurance"
            checked={Insurance === "Do not have insurance"}
            onChange={handleOptionChangeInsurance}
          />
          <label>Do not have insurance</label>
        </div>


      </div>


      <div className="input_row">
        <div>
          <label>Do you have government insurance in addition to your commercial / employer insurance?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="No, I only have commercial/employer insurance."
            checked={
                AdditionalInsurance ===
              "No, I only have commercial/employer insurance."
            }
            onChange={handleOptionChangeAdditionalInsurance}
          />
          <label>
          No, I only have commercial/employer insurance.
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes, I also have government insurance."
            checked={AdditionalInsurance === "Yes, I also have government insurance."}
            onChange={handleOptionChangeAdditionalInsurance}
          />
          <label>Yes, I also have government insurance.</label>
        </div>

    
    


      </div>





    </div>
  );
};
export default FinalInfo;
