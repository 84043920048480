import React, { useEffect, useState } from "react";

const AnythingApply = (props) => {

  const [AnythingApply,SetAnythingApply]=useState([])
  const [AnythingApplyLength,SetAnythingApplyLength]=useState(0)

  const handleOptionChange = (e) => {
    
    var AnythingApplyInArray=AnythingApply;
    if(e.target.checked===true)
    {
      AnythingApplyInArray.push(e.target.value)
    }
    else if(e.target.checked===false)
    {
      AnythingApplyInArray = AnythingApplyInArray.filter(item => item !== e.target.value);
    }

    console.log('AnythingApplyInArray',AnythingApplyInArray)
    SetAnythingApplyLength(AnythingApplyInArray.length)
    
    SetAnythingApply(AnythingApplyInArray)
  };
  

  useEffect(() => {

    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.AnythingApplyPC) {
      SetAnythingApply(props.AnythingApplyPC);
    }
    if (props.AnythingApplyLengthPC) {
      SetAnythingApplyLength(props.AnythingApplyLengthPC);
    }



  }, []);

  useEffect(()=>{

   

    setTimeout(() => {
      var Err=0;
    
      if(AnythingApplyLength===0)
      {
       Err=1;
      }
      
      if (Err == 0) {
       props.EnableNextButton();
     } else {
       props.DisableNextButton();
     }

    }, 10);

    props.SetAnythingApplyPC(AnythingApply)
    props.SetAnythingApplyLengthPC(AnythingApplyLength)


  },[AnythingApplyLength])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>Do any of the following apply to you?</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Currently pregnant or possibly pregnant or actively trying to become pregnant"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Currently pregnant or possibly pregnant or actively trying to become pregnant')}
           
          />
          <label>
            Currently pregnant or possibly pregnant or actively trying to become
            pregnant
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Breastfeeding or bottle-feeding with breastmilk"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Breastfeeding or bottle-feeding with breastmilk')}
          />
          <label>Breastfeeding or bottle-feeding with breastmilk</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="End-stage kidney disease (on or about to be on dialysis)"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('End-stage kidney disease (on or about to be on dialysis)')}
          />
          <label>
            End-stage kidney disease (on or about to be on dialysis)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Current or prior eating disorder (anorexia/bulimia)"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Current or prior eating disorder (anorexia/bulimia)')}
          />
          <label>
          Current or prior eating disorder (anorexia/bulimia)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Current suicidal thoughts and/or prior suicidal attempt"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Current suicidal thoughts and/or prior suicidal attempt')}
          />
          <label>
          Current suicidal thoughts and/or prior suicidal attempt
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Cancer (active diagnosis or treatment)"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Cancer (active diagnosis or treatment)')}
          />
          <label>
          Cancer (active diagnosis or treatment)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="History of organ transplant on anti-rejection medication"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('History of organ transplant on anti-rejection medication')}
          />
          <label>
          History of organ transplant on anti-rejection medication
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Severe gastrointestinal condition (gastroparesis or inflammatory bowel disease)"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Severe gastrointestinal condition (gastroparesis or inflammatory bowel disease)')}
          />
          <label>
          Severe gastrointestinal condition (gastroparesis or inflammatory bowel disease)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="History of or current pancreatitis"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('History of or current pancreatitis')}
          />
          <label>
          History of or current pancreatitis
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Type 1 diabetes"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Type 1 diabetes')}
          />
          <label>
          Type 1 diabetes
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Type 2 diabetes on insulin"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Type 2 diabetes on insulin')}
          />
          <label>
          Type 2 diabetes on insulin
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Diabetic retinopathy (eye condition related to diabetes)"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Diabetic retinopathy (eye condition related to diabetes)')}
          />
          <label>
          Diabetic retinopathy (eye condition related to diabetes)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Personal or family history of thyroid cyst/nodule or thyroid cancer or medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Personal or family history of thyroid cyst/nodule or thyroid cancer or medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2')}
          />
          <label>
          Personal or family history of thyroid cyst/nodule or thyroid cancer or medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Current participation in or subscription to another medical weight loss program and/or currently taking another medication for weight loss"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('Current participation in or subscription to another medical weight loss program and/or currently taking another medication for weight loss')}
          />
          <label>
          Current participation in or subscription to another medical weight loss program and/or currently taking another medication for weight loss
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="None of the above"
            onChange={handleOptionChange}
            checked={AnythingApply.includes('None of the above')}
          />
          <label>
          None of the above
          </label>
        </div>




      </div>
    </div>
  );
};
export default AnythingApply;
