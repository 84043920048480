import React, { useEffect, useState } from "react";

const WeightChanged = (props) => {
  const [WeightChanged, SetWeightChanged] = useState("");

  const handleOptionChange = (e) => {
    SetWeightChanged(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.WeightChangedPC) {
      SetWeightChanged(props.WeightChangedPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
   
      props.SetWeightChangedPC(WeightChanged);

      var Err = 0;
      if (WeightChanged === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [WeightChanged]);
  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          How has your weight changed in the last 12 months?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Lost a significant amount"
            checked={WeightChanged === "Lost a significant amount"}
            onChange={handleOptionChange}
          />
          <label>Lost a significant amount</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Lost a little"
            checked={WeightChanged === "Lost a little"}
            onChange={handleOptionChange}
          />
          <label>Lost a little</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="About the same"
            checked={WeightChanged === "About the same"}
            onChange={handleOptionChange}
          />
          <label>About the same</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Gained a little"
            checked={WeightChanged === "Gained a little"}
            onChange={handleOptionChange}
          />
          <label>Gained a little</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Gained a significant amount"
            checked={WeightChanged === "Gained a significant amount"}
            onChange={handleOptionChange}
          />
          <label>Gained a significant amount</label>
        </div>


      </div>
    </div>
  );
};
export default WeightChanged;
