import React, { useEffect, useState } from "react";

const WillingTo = (props) => {
  const [WillingTo,SetWillingTo]=useState([])
  const [WillingToLength,SetWillingToLength]=useState(0)

  const handleOptionChange = (e) => {
    
    var WillingToInArray=WillingTo;
    if(e.target.checked===true)
    {
      WillingToInArray.push(e.target.value)
    }
    else if(e.target.checked===false)
    {
      WillingToInArray = WillingToInArray.filter(item => item !== e.target.value);
    }

    console.log('WillingToInArray',WillingToInArray)
    SetWillingToLength(WillingToInArray.length)
    
    SetWillingTo(WillingToInArray)
  };
  

  useEffect(() => {

    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.WillingToPC) {
      SetWillingTo(props.WillingToPC);
    }
    if (props.WillingToLengthPC) {
      SetWillingToLength(props.WillingToLengthPC);
    }



  }, []);

  useEffect(()=>{

   

    setTimeout(() => {
      var Err=0;
    
      if(WillingToLength===0)
      {
       Err=1;
      }
      
      if (Err == 0) {
       props.EnableNextButton();
     } else {
       props.DisableNextButton();
     }

    }, 10);

    props.SetWillingToPC(WillingTo)
    props.SetWillingToLengthPC(WillingToLength)


  },[WillingToLength])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>Are you willing to</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Currently pregnant or possibly pregnant or actively trying to become
            pregnant"
            onChange={handleOptionChange}
            checked={WillingTo.includes('Currently pregnant or possibly pregnant or actively trying to become pregnant')}
          />
          <label>
            Currently pregnant or possibly pregnant or actively trying to become
            pregnant
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="Increase your physical activity alongside medication. if clinically appropriate"
            onChange={handleOptionChange}
            checked={WillingTo.includes('Increase your physical activity alongside medication. if clinically appropriate')}
          />
          <label>
          Increase your physical activity alongside medication. if clinically appropriate
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            value="None of the above"
            checked={WillingTo.includes('None of the above')}
            onChange={handleOptionChange}
          />
          <label>
          None of the above
          </label>
        </div>

       
 
      </div>
    </div>
  );
};
export default WillingTo
