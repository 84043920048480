import React, { useEffect, useState } from "react";

const Bariatric = (props) => {
  const [Bariatric, SetBariatric] = useState("");
  const [BariatricDesc, SetBariatricDesc] = useState("");
  const handleOptionChange = (e) => {
    SetBariatric(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.BariatricPC) {
      SetBariatric(props.BariatricPC);
    }
    if (props.BariatricDescPC) {
      SetBariatricDesc(props.BariatricDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetBariatricDescPC(BariatricDesc);
      props.SetBariatricPC(Bariatric);

      var Err = 0;
      if (Bariatric === "") {
        Err = 1;
      } else if (Bariatric === "Yes" && BariatricDesc === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [Bariatric, BariatricDesc]);

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          Have you had bariatric (weight loss) surgery before?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={Bariatric === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={Bariatric === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

      {Bariatric == "Yes" ? (
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={BariatricDesc}
              value={BariatricDesc}
              onChange={(e) => {
                SetBariatricDesc(e.target.value);
              }}
              placeholder="If yes, please include date range and type of surgery.*"
            ></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Bariatric;
