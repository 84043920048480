import React, { useEffect, useState } from "react";

const SelfMedicationBefore = (props) => {
  const [SelfMedicationBefore, SetSelfMedicationBefore] = useState("");
  const [SelfMedicationBeforeDesc, SetSelfMedicationBeforeDesc] = useState("");
  const handleOptionChange = (e) => {
    SetSelfMedicationBefore(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.SelfMedicationBeforePC) {
      SetSelfMedicationBefore(props.SelfMedicationBeforePC);
    }
    if (props.SelfMedicationDescBeforePC) {
      SetSelfMedicationBeforeDesc(props.SelfMedicationDescBeforePC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetSelfMedicationDescBeforePC(SelfMedicationBeforeDesc);
      props.SetSelfMedicationBeforePC(SelfMedicationBefore);

      var Err = 0;
      if (SelfMedicationBefore === "") {
        Err = 1;
      } else if (SelfMedicationBefore === "Yes" && SelfMedicationBeforeDesc === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [SelfMedicationBefore, SelfMedicationBeforeDesc]);

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          Have you taken any prescription medications to lose weight before?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={SelfMedicationBefore === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={SelfMedicationBefore === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

      {SelfMedicationBefore == "Yes" ? (
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={SelfMedicationBeforeDesc}
              value={SelfMedicationBeforeDesc}
              onChange={(e) => {
                SetSelfMedicationBeforeDesc(e.target.value);
              }}
              placeholder="If yes, please include date range, name, dose, and frequency of your prescription medications*"
            ></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default SelfMedicationBefore;
