import React, { useEffect, useState } from "react";

const WeightManagement = (props) => {
  const [WeightManagement, SetWeightManagement] = useState("");
  const [WeightManagementDesc, SetWeightManagementDesc] = useState("");
  const handleOptionChange = (e) => {
    SetWeightManagement(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.WeightManagementPC) {
      SetWeightManagement(props.WeightManagementPC);
    }
    if (props.WeightManagementDescPC) {
      SetWeightManagementDesc(props.WeightManagementDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetWeightManagementDescPC(WeightManagementDesc);
      props.SetWeightManagementPC(WeightManagement);

      var Err = 0;
      if (WeightManagement === "") {
        Err = 1;
      } else if (WeightManagement === "Yes" && WeightManagementDesc === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [WeightManagement, WeightManagementDesc]);

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          Have you ever attempted to lose weight in a weight management program, such as through caloric restriction, exercise, or behavior modification?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={WeightManagement === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={WeightManagement === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

      {WeightManagement == "Yes" ? (
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={WeightManagementDesc}
              value={WeightManagementDesc}
              onChange={(e) => {
                SetWeightManagementDesc(e.target.value);
              }}
              placeholder="If yes, please provide brief details.*"
            ></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default WeightManagement;
