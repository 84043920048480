import React, { useEffect, useState } from "react";

const SelfMedication = (props) => {
  const [SelfMedication, SetSelfMedication] = useState("");
  const [SelfMedicationDesc, SetSelfMedicationDesc] = useState("");

  const [Pregnant,SetPregnant]=useState('')
  const [PregnantConsent,SetPregnantConsent]=useState(false)

  const [BreastFeeding,SetBreastFeeding]=useState('')

  const [CanshowForFemale,SetCanshowForFemale]=useState(false)

  const handleOptionChangeBreastFeeding=(e)=>{
    SetBreastFeeding(e.target.value)
  }

  const handleOptionChange = (e) => {
    SetSelfMedication(e.target.value);
  };

  const handleOptionChangePregnant = (e) => {
    SetPregnant(e.target.value);
  };

  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

    if (props.SelfMedicationPC) {
      SetSelfMedication(props.SelfMedicationPC);
    }
    if (props.SelfMedicationDescPC) {
      SetSelfMedicationDesc(props.SelfMedicationDescPC);
    }
    if(props.PreganantPC)
    {
      SetPregnant(props.PreganantPC)
    }
    if(props.PregnantConsentPC)
    {
      SetPregnantConsent(props.PregnantConsentPC)
    }
    if(props.BreastFeedingPC)
    {
      SetBreastFeeding(props.BreastFeedingPC)
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetSelfMedicationDescPC(SelfMedicationDesc);
      props.SetSelfMedicationPC(SelfMedication);

      var Err = 0;
      if (SelfMedication === "" || BreastFeeding==='Yes') {
        Err = 1;
      } else if ((SelfMedication === "Yes" && SelfMedicationDesc === "") || BreastFeeding==='Yes') {
        Err = 1;
      }
      else if (Pregnant==='Yes' && PregnantConsent===false)
      {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [SelfMedication, SelfMedicationDesc,BreastFeeding,Pregnant,PregnantConsent]);

  useEffect(()=>{

    SetCanshowForFemale(false)
    props.SetPreganantPC(Pregnant)
    props.SetPregnantConsentPC(PregnantConsent)
    props.SetBreastFeedingPC(BreastFeeding)
    

   if(BreastFeeding==='No')
   {
    SetCanshowForFemale(true)
   }
   if(Pregnant==='Yes' && PregnantConsent===true)
   {
    SetCanshowForFemale(true)
   }

   if(Pregnant==='Yes')
   {
    SetBreastFeeding('')
   }

   if(BreastFeeding==='Yes')
   {
    alert('You are not eligible')
    props.DisableNextButton()
   }
   


  },[Pregnant,PregnantConsent,BreastFeeding])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

   

      {props.SexPC === "FEMALE" ? (
        <div className="WomenSelfMedication_wrap">
          <div className="input_row">
            <div>
              <label>
                Are you currently pregnant or planning to become pregnant soon?
              </label>
            </div>
            <div className="radio_input_yes_no">
              <input
                type="radio"
                value="Yes"
                checked={Pregnant === "Yes"}
                onChange={handleOptionChangePregnant}
              />
              <label>Yes</label>

              <input
                type="radio"
                value="No"
                checked={Pregnant === "No"}
                onChange={handleOptionChangePregnant}
              />
              <label>No</label>
            </div>
          </div>
        </div>
      ) : null}

      {
        Pregnant==='Yes' ? <>

        <div>
          <h5>Please review and agree to the following breastfeeding/pregnancy precaution</h5>
        </div>
        <div>
          <h6>Read the following for more information about the product and it's potential side effects:</h6>
        </div>
        <p>
          
Pregnancy and Breastfeeding precaution
The FDA says that these medications may pose a risk to the developing fetus (baby) if you get pregnant while taking it. "The pill" (oral contraception) is not enough because this medication can interfere with the effectiveness. For birth control you must use condoms, an IUD, or an implanted device. Official FDA
recommendation: For the first month after first starting a weight loss medication, and for the first month after each dose escalation, continue using your oral contraception and add a barrier method of contraception (such as condoms) to prevent pregnancy.
Alternatively, you can switch to a non-oral contraceptive method (such as an IUD or implant) before starting your medication. When you stop the medication, you must continue using a backup method (like condoms) for two months after stopping to allow the medication to fully leave your system before getting pregnant. Similarly, it is not known to be safe in breastfeeding. If you are breastfeeding, consult your doctor for safe weight loss options
        </p>
        <input type="checkbox" checked={PregnantConsent===true} onChange={(e)=>{SetPregnantConsent(e.target.checked)}}/>By checking this box, you acknowledge the above information
        </>: null
      }

      {
        Pregnant==='No' ?  <div className="SelfMedication_wrap">
        <div className="input_row">
          <div>
            <label>
              Are you currently breastfeeding?
            </label>
          </div>
          <div className="radio_input_yes_no">
            <input
              type="radio"
              value="Yes"
              checked={BreastFeeding === "Yes"}
              onChange={handleOptionChangeBreastFeeding}
            />
            <label>Yes</label>

            <input
              type="radio"
              value="No"
              checked={BreastFeeding === "No"}
              onChange={handleOptionChangeBreastFeeding}
            />
            <label>No</label>
          </div>
        </div>

     
      </div> : null
      }




{(props.SexPC === "MALE" || CanshowForFemale===true) ? (
        <div className="SelfMedication_wrap">
          <div className="input_row">
            <div>
              <label>
                Are you currently taking a GLP-1 medication such as
                Ozempic,Wegovy,Zepbound,Mounjaro,Semaglutide,Tirzepatide?
              </label>
            </div>
            <div className="radio_input_yes_no">
              <input
                type="radio"
                value="Yes"
                checked={SelfMedication === "Yes"}
                onChange={handleOptionChange}
              />
              <label>Yes</label>

              <input
                type="radio"
                value="No"
                checked={SelfMedication === "No"}
                onChange={handleOptionChange}
              />
              <label>No</label>
            </div>
          </div>

          {SelfMedication == "Yes" ? (
            <div className="input_row">
              <div className="textarea_row">
                <textarea
                  rows={6}
                  defaultValue={SelfMedicationDesc}
                  value={SelfMedicationDesc}
                  onChange={(e) => {
                    SetSelfMedicationDesc(e.target.value);
                  }}
                  placeholder="If yes, please include name, dose, and frequency of all your medications, including prescriptions, OTC,
  and supplements*"
                ></textarea>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}



    </div>
  );
};
export default SelfMedication;
