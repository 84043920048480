import React, { useEffect, useState } from "react";

const OpiatePainMedication = (props) => {
  const [OpMedication, SetOpMedication] = useState("");
  const [OpMedicationDesc, SetOpMedicationDesc] = useState("");
  const handleOptionChange = (e) => {
    SetOpMedication(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.OpMedicationPC) {
      SetOpMedication(props.OpMedicationPC);
    }
    if (props.OpMedicationDescPC) {
      SetOpMedicationDesc(props.OpMedicationDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetOpMedicationDescPC(OpMedicationDesc);
      props.SetOpMedicationPC(OpMedication);

      var Err = 0;
      if (OpMedication === "") {
        Err = 1;
      } else if (OpMedication === "Yes" && OpMedicationDesc === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [OpMedication, OpMedicationDesc]);

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
          Are you currently taking, plan to take, or have recently (within the last 3 months) taken opiate pain medications and/or opiate-based street drugs?
          </label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={OpMedication === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={OpMedication === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

      {OpMedication == "Yes" ? (
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={OpMedicationDesc}
              value={OpMedicationDesc}
              onChange={(e) => {
                SetOpMedicationDesc(e.target.value);
              }}
              placeholder="If yes, please include date range, dose, and frequency of your opiate pain medications and/or opiate-
              based street drugs*"
            ></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default OpiatePainMedication;
